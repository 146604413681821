
import firebase from "firebase/compat"
import "firebase/compat/auth"
import Webservice from "../webservice";
const jose = require('jose')

function getCurrentUser(shouldCreateAnonymous){
    return new Promise(function (resolve, reject){
        firebase.auth().onAuthStateChanged((user)=>{
            if (user !== null){
                // we have a connected user
                user.getIdToken().then((idToken)=>{
                    resolve({
                        anonymous: false,
                        idToken:idToken,
                        originalUserObj : user
                    })
                }).catch((err)=>{
                    reject("impossible to get idToken: "+err)
                })
            } else {
                // first check if we have an anon user
                getAnonUserOrCreateOneIfExpired().then((user)=>{
                    resolve(user)
                }).catch(()=>{
                    // no anon user
                    if (shouldCreateAnonymous){
                        Webservice.createAnonymousUser().then((res)=> {
                            // save anon user
                            localStorage.setItem('jwt_anon', res.data.jwt);
                            resolve({
                                anonymous: true,
                                idToken: res.data.jwt
                            })
                        }).catch((err)=> {
                            reject("impossible to create anonymous user:"+err)
                        })
                    } else {
                        reject("no firebase user")
                    }
                })
            }
        })
    })
}

function signOut(){
    return new Promise(function (resolve,reject){
        localStorage.setItem('jwt_anon', "");
        firebase.auth().signOut().then(()=>{
            resolve("signedOut")
        }).catch((err)=>{
            reject(err)
        })

    })
}

function createAnonUser(){
    return new Promise(function (resolve,reject) {
        Webservice.createAnonymousUser().then((res) => {
            // save anon user
            localStorage.setItem('jwt_anon', res.data.jwt);
            resolve({
                anonymous: true,
                idToken: res.data.jwt
            })
        }).catch((err) => {
            reject("impossible to create anonymous user:" + err)
        })
    })
}

function getOrCreateAnonUser(){
    return new Promise(function (resolve,reject){
        getAnonUserOrCreateOneIfExpired().then((user)=>{
            resolve(user)
        }).catch(()=>{
            // no anon user create one
            createAnonUser().then((user)=>{
                resolve(user)
            }).catch((err)=>{
              reject(err)
            })
        })
    })
}


function removeAnonUserFromLocalStorage(){
    return new Promise(function (resolve) {
        localStorage.setItem('jwt_anon', "");
        resolve()
    });

}

function mergeUser(){
    return new Promise(function (resolve,reject){
        // retrieve current user firebase
        getCurrentUser(false).then((firebaseUser)=>{
            getAnonUserOrCreateOneIfExpired().then((anonUser)=>{
                Webservice.mergeUser(firebaseUser.idToken,anonUser.idToken).then(()=>{
                    // remove anon user
                    removeAnonUserFromLocalStorage().then(()=>{
                        // all good, merged
                        // anon user removed
                        resolve()
                    }).catch((err)=>{
                        reject("impossible to remove anon user: "+err)
                    })
                }).catch((err)=>{
                    reject("impossible to merge: "+err)
                })
            }).catch((err)=>{
                reject("impossible to get annon user: "+err)
            })
        }).catch((err)=>{
            // no firebase user
            reject("impossible to get firebase user: "+err)
        })
    })
}

function getAnonUserOrCreateOneIfExpired(){
    return new Promise(function (resolve,reject){
        let jwtStorage = localStorage.getItem('jwt_anon');
        if (!jwtStorage || jwtStorage === ""){
            createAnonUser().then((newUser) => {
                resolve(newUser)
            }).catch((err)=>{
                reject("err while create anon "+err)
            })
        } else {
            if (isJWTExpired(jwtStorage)){
                // we have an anon user but JWT is expired
                createAnonUser().then((newUser) => {
                    resolve(newUser)
                }).catch((err)=>{
                    reject("err while create anon "+err)
                })
            } else {
                resolve({
                    anonymous: true,
                    idToken: jwtStorage
                })
            }
        }
    })
}


function isJWTExpired(token){
    if (token) {
        const expiry = jose.decodeJwt(token).exp;
        const now = new Date();
        return now.getTime() > expiry * 1000;
    }
    return false;
}

export default {
    getCurrentUser:getCurrentUser,
    signOut : signOut,
    mergeUser:mergeUser,
    getOrCreateAnonUser:getOrCreateAnonUser,
    createAnonUser:createAnonUser,
    isJWTExpired:isJWTExpired
}