<template>

  <nav class="navbar navbar-expand-lg navbar-light " style="background-color: #e3f2fd;">
    <a class="navbar-brand" href="/">{{bookTitle}}</a>


    <div class="collapse navbar-collapse" id="navbarText">
      <ul class="navbar-nav mr-auto">

        <li class="nav-item active">
          <router-link :to="{name:this.routes.BUY}" class="nav-link" href="#">Buy</router-link>
        </li>
        <li class="nav-item active">
          <router-link :to="{name:this.routes.FEEDBACK}" class="nav-link" href="#">Feedback</router-link>
        </li>
        <li class="nav-item active">
          <router-link :to="{name:this.routes.SUBSCRIBE}" class="nav-link" href="#">Subscribe</router-link>
        </li>
        <li class="nav-item active" >
          <router-link :to="{name:this.routes.TUTORIAL,params : {tutorialId: this.tutorialId, slug: 'build-ecommerce-website-golang-vuejs-gin-aws-lambda'}}" class="nav-link" href="#">Video Tutorial</router-link>
        </li>
        <li class="nav-item active">
          <router-link :to="{name:this.routes.PRODUCTS}" class="nav-link" href="#">Products</router-link>
        </li>
        <li class="nav-item active">
          <router-link :to="{name:this.routes.MY_COURSES}" class="nav-link" href="#">My Courses</router-link>
        </li>
      </ul>
    </div>
    <div class="" id="navbarRight">
      <ul class="navbar-nav mr-auto">
        <li class="nav-item active">
          <router-link :to="{name:this.routes.CART}" class="nav-link" href="#">Cart</router-link>
        </li>
        <li class="nav-item active" v-if="showLogin">
          <router-link :to="{name:this.routes.LOGIN}" class="nav-link" href="#">Login</router-link>
        </li>
        <li class="nav-item active" v-if="showSignOut">
          <a @click="signOut" class="nav-link" href="#">Sign Out</a>
        </li>
      </ul>
    </div>
    <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarText" aria-controls="navbarText" aria-expanded="false" aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button>
  </nav>

</template>

<script>
import Consts from '@/consts'
import AuthHelper from '@/authHelper'
import Webservice from "@/webservice";

export default {
  name: "NavBar",
  data() {
    return {
      bookTitle : Consts.BOOK_TITLE,
      routes : Consts.ROUTE_NAMES,
      loading: false,
      showLogin : false,
      showSignOut : false,
      tutorialId: process.env.VUE_APP_MAIN_TUTORIAL_ID,

    }
  },
  created() {
    this.resetNavbarStatus()
    //this.fetchData()
  },
  methods:{
    fetchData(){
      Webservice.getHomeData().then((res)=> {
        this.homeData = res.data
      }).catch((err)=> {
        console.error("impossible to add to cart :"+err)
        this.makeToast("Error", "An unexpected error has occurred, please try again later", "danger")
      })
    },
    resetNavbarStatus(){
      this.loading = true
      this.showLogin = false
      this.showSignOut = false
      AuthHelper.getCurrentUser(false).then((user)=>{
        console.log(user)
        if(user.anonymous){
          this.showLogin = true
        } else {
          this.showSignOut = true
        }
        this.loading = false
      }).catch((err)=> {
        console.error(err)
        this.showLogin = true
        this.loading = false
      })
    },
    signOut(){
      this.loading = true
      AuthHelper.signOut().then(()=>{
        this.resetNavbarStatus()
        // redirect the user to the home page
        this.$router.push({name: Consts.ROUTE_NAMES.HOME})
      })
    }
  }
}
</script>

<style scoped>
</style>